import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 20px;
  color: #fff;
  padding: 8px 10px;
  word-wrap: break-word;
`;

const UserDialog = (props) => {
  const { message } = props;
  const content = message
    .split('\n')
    .map((element, idx) => <div key={idx}>{element}</div>);

  return <Container>{content}</Container>;
};

export default UserDialog;

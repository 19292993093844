import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.textarea`
  line-height: 24px;
  padding: 14px 12px;
  resize: none;
  border-color: #5fd2da;
`;

const TextArea = (props) => {
  const {
    autoSize, // autoSize={{ minRows: 1, maxRows: 10 }}
    onBlur = () => {},
    onChange = () => {},
    value = '',
  } = props;
  const elementRef = useRef();

  const [elementHeight, setElementHeight] = useState('');

  const calculateHeight = () => {
    const minRows = autoSize.minRows ?? 1; // 最小行數
    const maxRows = autoSize.maxRows ?? 9999999; // 最大行數
    const computedStyle = getComputedStyle(elementRef.current);
    const lineHeight = parseInt(computedStyle.lineHeight);
    const paddingTop = parseInt(computedStyle.paddingTop);
    const borderWidth = parseInt(computedStyle.borderWidth);
    const contentHeight = elementRef.current.scrollHeight - paddingTop * 2;
    const lines = Math.floor(contentHeight / lineHeight);
    const calculatedRows = Math.min(maxRows, Math.max(minRows, lines)); // 限制在最小和最大行數之間
    setElementHeight(
      `${calculatedRows * lineHeight + paddingTop * 2 + borderWidth * 2}px`
    );
  };

  useEffect(() => {
    if (!autoSize) return;
    if (value) {
      calculateHeight();
    } else {
      setElementHeight('');
    }
  }, [value]);

  return (
    <Container
      {...props}
      ref={elementRef}
      rows={autoSize ? autoSize.minRows : 2}
      style={{ height: elementHeight }}
      autoCapitalize="off"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextArea;

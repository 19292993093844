import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  color: #fff;
  padding: 8px 12px;
  height: fit-content;
  text-align: center;

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      border: 1px solid ${props.color};
    `}
`;

const Button = (props) => {
  const { children, color, disabled = false, onClick = () => {} } = props;

  const handleClick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <Container
      {...props}
      color={color}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </Container>
  );
};

export default Button;

import React from 'react';
import styled, { keyframes } from 'styled-components';

import IconChef from 'images/icons/recipe-chef.png';

const bounce = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
`;

const QuestionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const IconAI = styled.img`
  margin-right: 5px;
  width: 37px;
  height: 37px;
`;

const Question = styled.div`
  max-width: 250px;
  background-color: #f2f2f2;
  border-radius: 16px 16px 16px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 23px;
  padding: 6px 12px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingText = styled.div``;

const CircleList = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
  height: 23px;
`;

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  animation: ${bounce} 0.5s ease-in-out infinite alternate;
  background-color: #2f90c8;

  &:nth-child(2n) {
    background-color: #da4543;
    animation-delay: 0.1s;
  }

  &:nth-child(3n) {
    animation-delay: 0.2s;
  }
`;

const Loading = (props) => {
  const { text = '' } = props;

  return (
    <LoadingWrapper>
      {text && <LoadingText>{text}</LoadingText>}
      <CircleList>
        {Array(10)
          .fill(null)
          .map((_, idx) => {
            return <Circle key={idx} />;
          })}
      </CircleList>
    </LoadingWrapper>
  );
};

const BotDialog = (props) => {
  const {
    icon,
    loading = false,
    loadingText = '',
    message = '',
    style,
  } = props;

  // 處理換行字元
  const formatMessage = message.split('\n').map((msg, idx) => {
    if (idx === 0) return msg;
    return (
      <span key={idx}>
        <br />
        {msg}
      </span>
    );
  });

  return (
    <QuestionWrapper style={style}>
      <IconAI src={icon ? icon : IconChef} />
      <Question>
        {loading ? <Loading text={loadingText} /> : formatMessage}
      </Question>
    </QuestionWrapper>
  );
};

export default BotDialog;
